import useStyles from "./styles"
import defaultHeaderImage from "./defaultHeaderImage.png"
import { Typography } from "@material-ui/core"
import { t } from "../../../locales/utils"

const HeaderImage: React.FC = () => {
  const classes = useStyles()
  const mainImage = defaultHeaderImage

  return (
    <>
      <div className={classes.imageContainer} data-testid="HeadeImage-imageContainer">
        {mainImage && (
          <img className={classes.image} alt="home" src={mainImage} />
        )}
      </div>
      <div className={classes.layer} data-testid="HeadeImage-layer" />
      <div className={classes.headerContainer} data-testid="HeadeImage-headerContainer">
        <Typography align="left" variant="h3" className={classes.header}>
          {t("header.welcomeTitle")}
        </Typography>
        <Typography align="left" variant="h5" className={classes.subheader}>
          {t("header.welcomeMessage")}
        </Typography>
      </div>
    </>
  )
}

export default HeaderImage
